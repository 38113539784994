import React from 'react'
import { Row, Col } from 'react-bootstrap'
import I18n from '../../../i18n-js/locales.js'

export default function ActivityMel(props) {
  return (
    <>
      {props.activity.activity_type == 'Grant' &&
        <Row className="pb-2">
          <Col md={4} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.beneficiaries_narrative')}</dt>
          </Col>
          <Col md={8}>
            <dd>{props.activity.beneficiaries_narrative}</dd>
          </Col>
        </Row>
      }
      <Row className="pb-2">
        <Col md={4} className="d-flex flex-row-reverse">
        <dt>{I18n.t('activerecord.attributes.activity.mel_plan')}</dt>
        </Col>
        <Col md={8}>
          <dd>{props.activity.mel_plan}</dd>
        </Col>
      </Row>
      {props.activity.activity_type == 'Grant' &&
        <Row className="pb-2">
          <Col md={4} className="d-flex flex-row-reverse">
          <dt>{I18n.t('activerecord.attributes.activity.summary_of_achievements')}</dt>
          </Col>
          <Col md={8}>
            <dd>{props.activity.summary_of_achievements}</dd>
          </Col>
        </Row>
      }
      <Row className="pb-2">
        <Col md={4} className="d-flex flex-row-reverse">
          <dt>{I18n.t('activerecord.attributes.activity.lessons_learned')}</dt>
        </Col>
        <Col md={8}>
          <dd>{props.activity.lessons_learned}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={4} className="d-flex flex-row-reverse">
        <dt>{I18n.t('activerecord.attributes.activity.success_stories')}</dt>
        </Col>
        <Col md={8}>
          <dd>{props.activity.success_stories}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={4} className="d-flex flex-row-reverse">
        <dt>{I18n.t('activerecord.attributes.activity.performance_results')}</dt>
        </Col>
        <Col md={8}>
          <dd>{props.activity.performance_results}</dd>
        </Col>
      </Row>
    </>
  )
}


