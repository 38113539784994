import React from "react"
import { Controller } from "@hotwired/stimulus"
import { createRoot } from "react-dom/client"
import KnowledgeManagementEntrypoint from "../components/KnowledgeManagementEntrypoint"


// Connects to data-controller="knowledgemanagement"
export default class extends Controller {
  static values = { userid: Number, resourceid: Number, tags: Array, searchterms: String, token: String }
  connect() {
    const existingRoot = document.querySelector('.react');
    const root = existingRoot ? existingRoot : createRoot(this.element)
    root.render(<KnowledgeManagementEntrypoint 
                                      tags={this.tagsValue || []}
                                      searchTerms={this.searchtermsValue || ""}
                                      userId={this.useridValue} 
                                      resourceId={this.resourceidValue}
                                      authenticityToken={this.tokenValue || ""} />);
  }
}
