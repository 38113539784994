
import React from 'react'
import SortableTable from "../../tables/SortableTable"
import { appConstants } from '../../../utils/constants.js'
import { BsPen } from "react-icons/bs"
import I18n from '../../../i18n-js/locales.js'
import { SelectColumnFilter } from '../../tables/tableFilters.js'

export default function ActivityDeliverables(props) {

  const tableColumns = [
    {
      "Header": I18n.t('activerecord.attributes.activity_deliverable.name'),
      "accessor": "name"
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_deliverable.description'),
      "accessor": "description",
      disableFilters: true,
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_deliverable.amount'),
      "accessor": "amount",
      disableFilters: true,
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_deliverable.currency_type'),
      "accessor": "currency_type",
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_deliverable.due_date'),
      "accessor": "due_date",
      disableFilters: true,
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_deliverable.est_payment_date'),
      "accessor": "est_payment_date",
      disableFilters: true,
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_deliverable.paid_date'),
      "accessor": "paid_date",
      disableFilters: true,
    }
  ]

  // Edit link for activity list
  const editLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <>{row["original"]["name"]} {row["original"]["can_edit"] ? <a href={`${appConstants.ACTIVITY_DELIVERABLES_URL}/${row["original"]["id"]}/edit`} data-turbo-frame='modal'> <BsPen /></a> : "" }</>
        : cell.render('Cell')
    )
  }

  return (
    <SortableTable
      data={props.activityDeliverables}
      columns={tableColumns}
      useRenderFunction={true}
      cellContent={editLink}
    />  
  )
}

