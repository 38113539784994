import React, { useEffect, useState, useContext }  from 'react'
import { Badge } from 'react-bootstrap'
import axios from 'axios'
import { KnowledgeManagementContext } from '../../../utils/context'
import { appConstants } from '../../../utils/constants.js'

export default function TagFilters(props) {
  const knowledgeManagementContext = useContext(KnowledgeManagementContext)
  const [availableTags, setAvailableTags] = useState({})

  const fetchAvailableTags = async () => {
    const res = await axios.get(`${appConstants.KM_AVAILABLE_TAGS_URL}?tag_ids=${knowledgeManagementContext.selectedTags.join()}&search_terms=${knowledgeManagementContext.searchTerms}`)
    setAvailableTags(res.data)
  }

  useEffect(() => {
    fetchAvailableTags()
  }, [knowledgeManagementContext.searchTerms, knowledgeManagementContext.selectedTags])

  const addTag = (e) => {
    const value = e.target.value;
    let newArray = knowledgeManagementContext.selectedTags.slice()
    newArray.push(value)
    
    knowledgeManagementContext.setSelectedTags(newArray)
    knowledgeManagementContext.setResourceId(null)
  }

  const removeTag = (e) => {
    const value = Number(e.target.id);
    let newArray = knowledgeManagementContext.selectedTags.slice();
    newArray = newArray.filter(function(item) {
      return item !== value && item != null && item != ""
    })

    knowledgeManagementContext.setSelectedTags(newArray)
    knowledgeManagementContext.setResourceId(null)
  }

  return (
    <>
      { Object.keys(availableTags).map(key =>
        <small key={key}>
          <strong>{key}</strong><br/>
          { availableTags[key]["selected"].map(v =>  <Badge style={{cursor: "pointer"}} variant="info" key={v.id} id={v.id} onClick={(e) => removeTag(e)}>{v.name}</Badge>)}
          <ul className="list-unstyled" key={key}>
            { availableTags[key]["available"].map(v =>  <li key={v.id} value={v.id} onClick={(e) => addTag(e)}  style={{cursor: "pointer"}}>{v.name} <Badge variant="light">{v.resource_count}</Badge></li>)}
          </ul>
        </small>
        )
      }
    </>
  )
}
