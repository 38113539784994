import React from 'react'
import { Row, Col } from 'react-bootstrap'
import I18n from '../../../i18n-js/locales.js'

export default function ActivityPurpose(props) {
  return (
    <>
      <Row className="pb-2 pt-2">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.number')}</dt>
        </Col>
        <Col md={9}>
          <dd>{props.activity.activity_number}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{props.activity.activity_type == 'Grant' ? I18n.t('activerecord.attributes.activity.awardee') : I18n.t('activerecord.attributes.activity.supplier')}</dt>
        </Col>
        <Col md={9}>
          <dd>{props.activity.awardee}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.background')}</dt>
        </Col>
        <Col md={9}>
          <dd>{props.activity.background}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.objectives')}</dt>
        </Col>
        <Col md={9}>
          <dd>{props.activity.objectives}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{props.activity.activity_type == 'Grant' ? I18n.t('activerecord.attributes.activity.summary') : I18n.t('activerecord.attributes.activity.expected_outcome')}</dt>
        </Col>
        <Col md={9}>
          <dd>{props.activity.summary}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.justification')}</dt>
        </Col>
        <Col md={9}>
          <dd>{props.activity.justification}</dd>
        </Col>
      </Row>
      {props.activity.activity_type != 'Grant' &&
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.beneficiaries')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.beneficiaries}</dd>
          </Col>
        </Row>
      }
      <Row className="pb-2">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.activity.concept_note_cor_approved_date')}</dt>
        </Col>
        <Col md={9}>
          <dd>{props.activity.concept_note_cor_approved_date}</dd>
        </Col>
      </Row>
    </>
  )
}
