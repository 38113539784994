import React from 'react'
import { Row, Col } from 'react-bootstrap'
import I18n from '../../../i18n-js/locales.js'

export default function ActivityImplementationPlan(props) {
  return (
    <>
      <Row className="pb-2">
        <Col md={4} className="d-flex flex-row-reverse">
          <dt>{I18n.t('activerecord.attributes.activity.deliverables')}</dt>
        </Col>
        <Col md={8}>
          <dd>{props.activity.awardee_reporting}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={4} className="d-flex flex-row-reverse">
        <dt>{I18n.t('activerecord.attributes.activity.coordination')}</dt>
        </Col>
        <Col md={8}>
          <dd>{props.activity.coordination}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={4} className="d-flex flex-row-reverse">
        <dt>{I18n.t('activerecord.attributes.activity.special_instructions')}</dt>
        </Col>
        <Col md={8}>
          <dd>{props.activity.special_instructions}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={4} className="d-flex flex-row-reverse">
          <dt>{I18n.t('activerecord.attributes.activity.branding_and_marketing')}</dt>
        </Col>
        <Col md={8}>
          <dd>{props.activity.branding_and_marketing}</dd>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col md={4} className="d-flex flex-row-reverse">
        <dt>{I18n.t('activerecord.attributes.activity.implementation_schedule')}</dt>
        </Col>
        <Col md={8}>
          <dd>{props.activity.implementation_schedule}</dd>
        </Col>
      </Row>
    </>
  )
}

