import React, { useState } from 'react'
import Column from './Column'
import { DragDropContext } from 'react-beautiful-dnd';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

export default function Board(props) {
  const [homeIndex, setHomeIndex] = useState(null)
  const [columns, setColumns] = useState(props.board.columns || {})

  const onDragStart = (start) => {
    const startHomeIndex = props.board.columnOrder.indexOf(start.source.droppableId)
    setHomeIndex(startHomeIndex)
  }

  const onDragEnd = (result) => {
    setHomeIndex(null)

    const { destination, source, draggableId } = result

    // Moving aborted
    if(!destination) {
      return
    }

    //Moving to original spot
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const start = columns[source.droppableId]
    const finish = columns[destination.droppableId]

    // Moving within same column 
    if(start === finish) {
      const newActivityIds = Array.from(start.activityIds)
      newActivityIds.splice(source.index, 1)
      newActivityIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        activityIds: newActivityIds,
      }

      const newColumns = {
        ...columns,
        [newColumn.id]: newColumn,
      }

      setColumns(newColumns)

      return
    }

    // Moving from one list to another
    const startActivityIds = Array.from(start.activityIds);
    startActivityIds.splice(source.index, 1);
    const newStart = {
      ...start,
      activityIds: startActivityIds,
    }

    const finishActivityIds = Array.from(finish.activityIds);
    finishActivityIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      activityIds: finishActivityIds,
    }

    const newColumns = {
      ...columns,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    }

    props.persistStatus(draggableId, destination.droppableId)
    setColumns(newColumns)
  }

  const dropDisabled = (index) => {
    if (props.board.multidirectional && props.board.canEdit) {
      return false
    } else if (!props.board.canEdit) {
      return true
    } else {
      return index < homeIndex
    }
  }

  return (
    <DragDropContext 
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <Container fluid>
        <Row>
          {props.board.columnOrder.map((columnId, index) => {
            const column = columns[columnId]
            const activities = column.activityIds.map(activityId => props.board.activities[activityId])

            return <Column key={column.id} column={column} activities={activities} isDropDisabled={dropDisabled(index)} activityContent={props.activityContent} />
          })}
        </Row>
      </Container>
    </DragDropContext>
  )
}
