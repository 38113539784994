import React from 'react'
import styled from 'styled-components'
import Activity from './Activity'
import { Droppable } from 'react-beautiful-dnd';
import Col from 'react-bootstrap/Col'

const Title = styled.h6`
  padding: 4px;
`;
const ActivityList = styled.div`
  padding: 4px;
  border-radius: 5px;
  transition: background-color 0.5s ease;
  background-color: ${props => (props.isDraggingOver ? 'lightgrey' : '')}  !important;
  flex-grow: 1;
  min-height: 95%;
`;

export default function Column(props) {

  return (
    <Col className="border rounded bg-light mx-md-1">

      <Title>{props.column.title}</Title>
        <Droppable 
          droppableId={props.column.id}
          isDropDisabled={props.isDropDisabled}
        >
          {(provided, snapshot) => (
            <ActivityList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
            {props.activities.map((activity, index) => <Activity key={activity.id} activity={activity} index={index} activityContent={props.activityContent} />)}
            {provided.placeholder}
            </ActivityList>
          )}
        </Droppable>

            
    </Col>
  )
}
