import React from 'react'
import { ListGroup, Row, Col } from 'react-bootstrap'
import { appConstants } from '../../../utils/constants.js'
import { BsPen, BsTrash } from "react-icons/bs"
import I18n from '../../../i18n-js/locales.js'
import SortableTable from "../../tables/SortableTable"
import { SelectColumnFilter } from '../../tables/tableFilters.js'

export default function ActivityDocuments(props) {

  const tableColumns = [
    {
      "Header": I18n.t('activerecord.attributes.resource.name'),
      "accessor": "name"
    },
    {
      "Header": I18n.t('activerecord.attributes.resource.url'),
      "accessor": "resource_url"
    },
    {
      "Header": I18n.t('activerecord.attributes.resource.document_type'),
      "accessor": "document_type",
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      "Header": I18n.t('labels.updated_by'),
      "accessor": "updated_by",
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      "Header": "",
      "accessor": "can_edit",
      disableFilters: true
    }
  ]

  // Edit link for activity list
  const editLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <>
            {row["original"]["can_edit"] ? 
              <a href={`${appConstants.RESOURCES_URL}/${row["original"]["id"]}/edit`} data-turbo-frame='modal'> {row["original"]["name"]}</a>  
            : <a href={`${appConstants.RESOURCES_URL}/${row["original"]["id"]}`} data-turbo-frame='modal'> {row["original"]["name"]}</a>   }
          </>
        : cell["column"]["id"] === "resource_url"
        ? <a href={row["original"]["resource_url"]} target="_blank" rel="noopener noreferrer">{row["original"]["filename"]}</a>
        : cell["column"]["id"] === "can_edit"
        ? <a href={`${appConstants.RESOURCES_URL}/${row["original"]["id"]}`} data-turbo-method="delete" data-turbo-confirm={I18n.t('warnings.are_you_sure')}><BsTrash /> </a>
        : cell.render('Cell')
    )
  }

  return (
    <SortableTable
      data={props.resources}
      columns={tableColumns}
      useRenderFunction={true}
      cellContent={editLink}
    />  
  )
}

