import React, { useContext }  from 'react'
import { Row, Col } from 'react-bootstrap'
import { BiSearch } from "react-icons/bi"
import I18n from '../../i18n-js/locales.js'
import { KnowledgeManagementContext } from '../../utils/context'
import SearchBar from './knowledgemanagement/SearchBar'
import TagFilters from './knowledgemanagement/TagFilters'
import ResourceList from './knowledgemanagement/ResourceList.js'
import ResourceDetails from './knowledgemanagement/ResourceDetails.js'


export default function KnowledgeManagement(props) {
  const knowledgeManagementContext = useContext(KnowledgeManagementContext)

  return (
    <Row>
      <Col md={3} className="border-end">
        <h5 className="border-bottom pb-2"><BiSearch /> Filter & Search</h5>
        <TagFilters />
      </Col>
      <Col md={9}>
        <div className="h5 mt-n1 mb-0 pb-1">
          <SearchBar />
        </div>
        {knowledgeManagementContext.resourceId ? <ResourceDetails /> : <ResourceList />}
      </Col>
    </Row>
  )
}
