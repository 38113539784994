import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import I18n from '../../../i18n-js/locales.js'

export default function ActivityDetails(props) {
  return (
    <>
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>General</Card.Title>
        <Row className="pb-2 pt-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.grump_activity_number')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.grump_activity_number}</dd>
          </Col>
        </Row>
        <Row className="pb-2 pt-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.activity_type')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.activity_type}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{props.activity.activity_type != 'Grant' ? I18n.t('activerecord.attributes.activity.activity_sub_type') : I18n.t('activerecord.attributes.activity.grant_type')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.activity_sub_type}</dd>
          </Col>
        </Row>
        {props.activity.activity_type != 'Grant' &&
          <Row className="pb-2">
            <Col md={3} className="d-flex flex-row-reverse pe-2">
              <dt>{I18n.t('activerecord.attributes.activity.project_type')}</dt>
            </Col>
            <Col md={9}>
              <dd>{props.activity.project_type}</dd>
            </Col>
          </Row>
        }
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.admin4')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.admin4}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.funding_source')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.funding_source}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.mod_number')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.mod_number}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.mod_date')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.mod_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.mod_justification')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.mod_justification}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{props.activity.activity_type == 'Grant' ? I18n.t('activerecord.attributes.activity.awardee') : I18n.t('activerecord.attributes.activity.supplier')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.awardee}</dd>
          </Col>
        </Row>
        {props.activity.activity_type == 'Grant' &&
          <Row className="pb-2">
            <Col md={3} className="d-flex flex-row-reverse pe-2">
              <dt>{I18n.t('activerecord.attributes.activity.grantee_uei')}</dt>
            </Col>
            <Col md={9}>
              <dd>{props.activity.grantee_uei}</dd>
            </Col>
          </Row>
        }
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{props.activity.activity_type == 'Grant' ? I18n.t('activerecord.attributes.activity.awardee_type') : I18n.t('activerecord.attributes.activity.supplier_type')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.awardee_type}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{props.activity.activity_type == 'Grant' ? I18n.t('activerecord.attributes.activity.awardee_contact') : I18n.t('activerecord.attributes.activity.supplier_contact')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.awardee_contact}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{props.activity.activity_type == 'Grant' ? I18n.t('activerecord.attributes.activity.grantee_email') : I18n.t('activerecord.attributes.activity.supplier_email')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.grantee_email}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.competition_mechanism')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.competition_mechanism}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.component')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.component}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.impact_area')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.impact_area}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.duration')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.duration}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.start_date')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.start_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.end_date')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.end_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.workplan_activity_number')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.workplan_activity_number}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.workplan_period')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.workplan_period}</dd>
          </Col>
        </Row>
        {props.activity.activity_type == 'Grant' &&
          <Row className="pb-2">
            <Col md={3} className="d-flex flex-row-reverse pe-2">
              <dt>{I18n.t('activerecord.attributes.activity.grant_themes')}</dt>
            </Col>
            <Col md={9}>
              <dd>{props.grantThemes.map((t, i) => <span className="badge bg-secondary me-1" key={i}>{t}</span>)}</dd>
            </Col>
          </Row>
        }
      </Card.Body>
    </Card>
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Classification</Card.Title>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.status')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.activity_status}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.eligibility')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.eligibility}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{props.activity.activity_type == 'Grant' ? I18n.t('activerecord.attributes.activity.grant_manager') : I18n.t('activerecord.attributes.activity.hcsp_technical')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.grant_manager}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.cleared_by')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.cleared_by}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.reviewed_by')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.reviewed_by}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.environmental_classification')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.environmental_classification}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.atv_check_date')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.atv_check_date}</dd>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    {props.activity.activity_type == 'Grant' &&
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Concurrence & Approval</Card.Title>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.approval_required')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.co_approval_required ? I18n.t('activerecord.attributes.activity.co_approval_required') : I18n.t('activerecord.attributes.activity.cor_approval_required') }</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.usaid_cor')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.usaid_cor}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.usaid_co')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.usaid_co}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.usaid_cor_alt')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.usaid_cor_alt}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2">
            <dt>{I18n.t('activerecord.attributes.activity.usaid_co_alt')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.usaid_co_alt}</dd>
          </Col>
        </Row>
        {props.canEdit ? (
          <>
          <Row className="pb-2">
            <Col md={3} className="d-flex flex-row-reverse pe-2"> 
              <dt>{I18n.t('activerecord.attributes.activity.review_recipients')}</dt>
            </Col>
            <Col md={9}>
              <dd>{props.activity.review_recipients}</dd>
            </Col>
          </Row>
          <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse pe-2"> 
            <dt>{I18n.t('activerecord.attributes.activity.approval_recipients')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.approval_recipients}</dd>
          </Col>
        </Row>
        </>
        ) : ""}
      </Card.Body>
    </Card>
    }
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Dates</Card.Title>
        {props.activity.activity_type == 'Grant' &&
          <Row className="pb-2">
            <Col md={3} className="d-flex flex-row-reverse">
              <dt>{I18n.t('activerecord.attributes.activity.entered_in_grump')}</dt>
            </Col>
            <Col md={9}>
              <dd>{props.activity.entered_in_grant_management_date}</dd>
            </Col>
          </Row>
}
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.rejected')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.rejected_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.cleared')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.cleared_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.usaid_approval_date')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.usaid_approval_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{props.activity.activity_type == 'Grant' ? I18n.t('activerecord.attributes.activity.awardee_signed') : I18n.t('activerecord.attributes.activity.supplier_signed_date')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.awardee_signed_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.start_date')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.start_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.end_date')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.end_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
          <dt>{I18n.t('activerecord.attributes.activity.cancelled')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.cancelled_date}</dd>
          </Col>    
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.suspended')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.suspended_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
          <dt>{I18n.t('activerecord.attributes.activity.completed')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.completed_date}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={3} className="d-flex flex-row-reverse">
          <dt>{I18n.t('activerecord.attributes.activity.closed')}</dt>
          </Col>
          <Col md={9}>
            <dd>{props.activity.closed_date}</dd>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Budget</Card.Title>
        <Row className="pb-2">
          <Col md={4} className="d-flex flex-row-reverse">
          <dt>{props.activity.activity_type == 'Grant' ? I18n.t('activerecord.attributes.activity.estimated_award') : I18n.t('activerecord.attributes.activity.total_activity_budget')}</dt>
          </Col>
          <Col md={8}>
            <dd>${props.activity.estimated_award}</dd>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col md={4} className="d-flex flex-row-reverse">
          <dt>{I18n.t('activerecord.attributes.activity.amount_disbursed')}</dt>
          </Col>
          <Col md={8}>
            <dd>${props.activity.amount_disbursed}</dd>
          </Col>
        </Row>
        {props.activity.activity_type == 'Grant' &&
          <>
          <Row className="pb-2">
            <Col md={4} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.original_budget')}</dt>
            </Col>
            <Col md={8}>
              <dd>${props.activity.original_budget}</dd>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={4} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.awardee_contribution')}</dt>
            </Col>
            <Col md={8}>
              <dd>${props.activity.awardee_contribution}</dd>
            </Col>
          </Row>
          <Row className="pb-2">
            <Col md={4} className="d-flex flex-row-reverse">
            <dt>{I18n.t('activerecord.attributes.activity.other_contribution')}</dt>
            </Col>
            <Col md={8}>
              <dd>${props.activity.other_contribution}</dd>
            </Col>
          </Row>
          </>
        }
      </Card.Body>  
    </Card>
    </>
  )
}


