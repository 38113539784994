import React, { useState } from 'react'
import ActivityDashboard from './pages/ActivityDashboard.js'
import { ActivityDashboardContext } from '../utils/context.js'

export default function ActivityDashboardEntrypoint(props) {
  const [activityId, setActivityId] = useState(props.activityId)
  const [activityTab, setActivityTab] = useState('list')

  const renderAD = () => {
    return <ActivityDashboard />
  }

  return (
    <ActivityDashboardContext.Provider 
      value={{ 
        userId: props.userId, 
        authenticityToken: props.authenticityToken,
        activityId: activityId,
        setActivityId: setActivityId,
        activityTab: activityTab,
        setActivityTab: setActivityTab,
      }}>
      {renderAD()}
    </ActivityDashboardContext.Provider>
  )
}
