import React from 'react'
import { 
  Row, 
  Col, 
  Button, 
  ListGroup } from 'react-bootstrap'
  import { BsPinMap } from "react-icons/bs"
  import { appConstants } from '../../../utils/constants.js'
  import { Map, Marker, ZoomControl } from "pigeon-maps"


export default function ActivityLocations(props) {
  return (
    <>
      <Row>
        <Col md={12}>
          <Map height={500} 
            center={props.mapCenter} 
            zoom={props.mapZoom}
            onBoundsChanged={({ center, zoom }) => { 
              props.setMapCenter(center) 
              props.setMapZoom(zoom) 
            }} 
          >
            <ZoomControl />
            {props.sites.map((s, ind) => (
              <Marker 
                key={ind}
                width={30}
                anchor={[parseFloat(s.latitude), parseFloat(s.longitude)]} 
                onClick={() => {
                  props.setMapCenter([parseFloat(s.latitude), parseFloat(s.longitude)])
                  props.setMapZoom(16)
                }} 
              />
            ))}
            
          </Map>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="d-flex flex-row-reverse border-bottom">
        <Button variant="link" size="sm" onClick={() => {
            props.setMapCenter(props.resetCoordinates)
            props.setMapZoom(props.resetZoom)
          }} >reset map</Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <ListGroup variant="flush">
            {props.sites.map((s, ind) => (
              <ListGroup.Item key={ind} variant="light" >
                <Row className="pb-2">
                  <Col md={1} className="d-flex flex-row-reverse">
                    <Button variant="link" size="sm" onClick={() => {
                      props.setMapCenter([parseFloat(s.latitude), parseFloat(s.longitude)])
                      props.setMapZoom(16)
                    }} ><BsPinMap /></Button>
                  </Col>
                  <Col md={11}>
                    {props.canEdit ? <a href={`${appConstants.SITES_URL}/${s.id}/edit`} data-turbo-frame='modal'>{s.name}</a> : s.name} <br />
                    {s.admin4} <br/>
                    {s.latitude}, {s.longitude} <br/>
                    ${s.amount_disbursed} 
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </>
  )
}

