import React from "react"
import { Controller } from "@hotwired/stimulus"
import { createRoot } from "react-dom/client"
import ActivityDashboardEntrypoint from "../components/ActivityDashboardEntrypoint"


// Connects to data-controller="knowledgemanagement"
export default class extends Controller {
  static values = { activityid: Number, userid: Number, token: String }
  connect() {
    const existingRoot = document.querySelector('.react')
    const root = existingRoot ? existingRoot : createRoot(this.element)
    root.render(<ActivityDashboardEntrypoint 
                                      userId={this.useridValue} 
                                      activityId={this.activityidValue}
                                      authenticityToken={this.tokenValue || ""} />);
  }
}
