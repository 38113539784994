import React, { useContext }  from 'react'
import { Container } from 'react-bootstrap'
import { ActivityDashboardContext } from '../../utils/context'
import Activities from './activitydashboard/Activities'
import Activity from './activitydashboard/Activity'

export default function KnowledgeManagement(props) {
  const activityDashboardContext = useContext(ActivityDashboardContext)

  const renderDashboard = () => {
    return activityDashboardContext.activityId ? (
      <Activity />
    ) : <Activities />

  }

  return (
    <Container>
      {renderDashboard()}
    </Container>
  )
}
