import React, { useEffect, useState, useContext }  from "react"
import { Container, Row, Col, Button } from 'react-bootstrap'
import axios from 'axios'
import I18n from '../../../i18n-js/locales.js'
import { KnowledgeManagementContext } from '../../../utils/context'
import { appConstants } from '../../../utils/constants.js'

export default function  ResourceDetails() {
  const knowledgeManagementContext = useContext(KnowledgeManagementContext)
  const [resource, setResource] = useState({})
  const [tags, setTags] = useState([])

  const fetchResource = async () => {
    const res = await axios.get(`${appConstants.RESOURCES_URL}/${knowledgeManagementContext.resourceId}.json`)
    setResource(res.data)
    setTags(res.data.tags)
  }

  useEffect(() => {
    fetchResource()
  }, [knowledgeManagementContext.resourceId])

  return (
    <Container>
      <Row className="pb-1">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.resource.name')}</dt>
        </Col>
        <Col md={9}>
          <dd>{resource.name}</dd>
        </Col>
      </Row>
      <Row className="pb-1">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.resource.url')}</dt>
        </Col>
        <Col md={9}>
          <dd><a href={resource.resource_url} target="_blank" rel="noopener noreferrer">{resource.filename}</a></dd>
        </Col>
      </Row>
      <Row className="pb-1">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.resource.document_type')}</dt>
        </Col>
        <Col md={9}>
          <dd>{resource.document_type}</dd>
        </Col>
      </Row>
      <Row className="pb-1">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.resource.description')}</dt>
        </Col>
        <Col md={9}>
          <dd>{resource.description}</dd>
        </Col>
      </Row>
      <Row className="pb-1">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>{I18n.t('activerecord.attributes.resource.tags')}</dt>
        </Col>
        <Col md={9}>
        <dd>{tags.map((t, i) => <span className="badge bg-secondary me-1" key={i}>{t}</span>)}</dd>
        </Col>
      </Row>
      <Row className="pb-1">
        <Col md={3} className="d-flex flex-row-reverse pe-2">
          <dt>Activity</dt>
        </Col>
        <Col md={9}>
        <dd>{resource.activity}</dd>
        </Col>
      </Row>
      <Row className="border-top pt-2">
        <Col md={8}>
          <Button variant="outline-secondary" onClick={()=>knowledgeManagementContext.setResourceId(null)}>{I18n.t('labels.back')}</Button>
        </Col>
        { resource.can_edit ? (
          <>
            <Col md={2}>
              <Button variant="outline-primary" href={`${appConstants.RESOURCES_URL}/${knowledgeManagementContext.resourceId}/edit`} data-turbo-frame='modal'>{I18n.t('labels.edit')}</Button>
            </Col>
            <Col md={2}>
              <Button variant="outline-danger" href={`${appConstants.RESOURCES_URL}/${knowledgeManagementContext.resourceId}`} data-turbo-method="delete" data-turbo-confirm={I18n.t('warnings.are_you_sure')}>{I18n.t('labels.delete')}</Button>
            </Col>
          </>
        ) : ""
      }
      </Row>
    </Container>
  )
}

