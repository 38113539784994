import React, { useEffect, useState, useContext }  from "react"
import { ListGroup } from 'react-bootstrap'
import axios from 'axios'
import { KnowledgeManagementContext } from '../../../utils/context'
import { appConstants } from '../../../utils/constants.js'

export default function ResourceList(props) {
  const knowledgeManagementContext = useContext(KnowledgeManagementContext)
  const [filteredResources, setFilteredResources] = useState([])

  const fetchFilteredResources = async () => {
    const res = await axios.get(`${appConstants.KM_SEARCHED_AND_FILTERED_RESOURCES_URL}?tag_ids=${knowledgeManagementContext.selectedTags.join()}&search_terms=${knowledgeManagementContext.searchTerms}`)
    setFilteredResources(res.data)
  }

  useEffect(() => {
    fetchFilteredResources()
  }, [knowledgeManagementContext.searchTerms, knowledgeManagementContext.selectedTags])

  return (
    <ListGroup variant="flush" className="border-light">
      { filteredResources.map(v =>  <ListGroup.Item key={v.id}><strong><a href='#' onClick={() => knowledgeManagementContext.setResourceId(v.id)}>{v.name}</a></strong><br/><small>{v.description}</small></ListGroup.Item>)}
    </ListGroup>
  )
}