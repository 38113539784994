import React, { useState, useContext } from "react"
import { Button, Form, InputGroup, OverlayTrigger,Tooltip } from 'react-bootstrap'
import { BiReset, BiSearch } from "react-icons/bi"
import { KnowledgeManagementContext } from '../../../utils/context'
//import I18n from 'i18n-js/locales.js'

export default function SearchBar(props) {
  const knowledgeManagementContext = useContext(KnowledgeManagementContext)
  const [searchValue, setSearchValue] = useState(knowledgeManagementContext.searchTerms || "")

  return (
    <InputGroup>
      <Form.Control
        type="text"
        value={searchValue}
        placeholder="Search"
        onChange={(e) => setSearchValue(e.target.value)} />
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip-search">
            Search
          </Tooltip>}
      >
        <Button variant="outline-secondary" onClick={() => {
          knowledgeManagementContext.setSearchTerms(searchValue)
          knowledgeManagementContext.setResourceId(null)
        }
        }><BiSearch /></Button>
      </OverlayTrigger>
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip-reset">
            Reset
          </Tooltip>}
      >
        <Button variant="outline-secondary" onClick={() => {
          console.log("reset")
            knowledgeManagementContext.setSearchTerms("")
            knowledgeManagementContext.setSelectedTags([])
            knowledgeManagementContext.setResourceId(null)
            setSearchValue("")
          }}><BiReset />
        </Button>
      </OverlayTrigger>
    </InputGroup>
  )
}