import React  from 'react'
import { useTable, useSortBy, useFilters } from 'react-table'
import BTable from 'react-bootstrap/Table'
import {FiArrowUpCircle, FiArrowDownCircle} from "react-icons/fi"
import { Filter, DefaultColumnFilter } from './tableFilters.js'
 
export default function SortableTable(props) {
  const data = props.data
  const columns = props.columns
  const link_path = props.link_path

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
      { 
        columns,
        data,
        defaultColumn: { Filter: DefaultColumnFilter },
      },
      useFilters,
      useSortBy
    )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? <FiArrowDownCircle /> : <FiArrowUpCircle />) : ""
  }

  return (
    <BTable striped bordered hover size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              column.show !== false && ( 
                <th className="align-baseline" {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')} &nbsp;
                    {generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </th>
              )
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  cell.column.show !== false && ( 
                    <td {...cell.getCellProps()}>
                      {
                        props.useRenderFunction
                        ? props.cellContent(cell, row)
                        : cell.render('Cell') 
                      }
                    </td>
                  )
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </BTable>
  )
}