import { createContext } from 'react'

export const KnowledgeManagementContext = createContext({
  selectedTags: [],
  searchTerms: "",
  setSearchTerms: (id) => {},
  setSelectedTags: (id) => {},
  userId: null,
  resourceId: null,
  setResourceId: (id) => {},
  authenticityToken: "",
})

export const ActivityDashboardContext = createContext({
  userId: null,
  activityId: null,
  setActivityId: (id) => {},
  authenticityToken: "",
  activityTab: null,
  setActivityTab: (t) => {},
})