import React, { useState } from 'react'
import KnowledgeManagament from './pages/KnowledgeManagament.js'
import { KnowledgeManagementContext } from '../utils/context.js'

export default function KnowledgeManagementEntrypoint(props) {
  const [searchTerms, setSearchTerms] = useState(props.searchTerms || "")
  const [selectedTags, setSelectedTags] = useState(props.tags || [])
  const [resourceId, setResourceId] = useState(props.resourceId)

  const renderKM = () => {
    return <KnowledgeManagament />
  }

  return (
    <KnowledgeManagementContext.Provider 
      value={{ 
        selectedTags: selectedTags,
        searchTerms: searchTerms,
        setSearchTerms: setSearchTerms,
        setSelectedTags: setSelectedTags,
        userId: props.userId,
        resourceId: resourceId, 
        setResourceId: setResourceId,
        authenticityToken: props.authenticityToken}}>
      {renderKM()}
    </KnowledgeManagementContext.Provider>
  )
}
