import I18n from '../i18n-js/locales.js'

export const formatValidationErrors = (resourceName, errors) => {
  let errorText = []
  for (const field in errors) {
    errorText = errorText.concat(errors[field].map(e => `${I18n.t(`activerecord.attributes.${resourceName}.${field}`)} ${e}`))
  }
  return errorText.join(';  ');
}

export const appConstants = {
  KM_AVAILABLE_TAGS_URL: '/kmp/available_tags',
  KM_SEARCHED_AND_FILTERED_RESOURCES_URL: '/kmp/searched_and_filtered_resources',
  RESOURCES_URL: '/resources',
  ACTIVITIES_URL: '/activities',
  ACTIVITY_NOTES_URL: '/activity_notes',
  SITES_URL: '/sites',
  KANBANS_URL: '/kanbans',
  UPDATE_ACTIVITY_STATUS_URL: '/update_activity_status',
  ACTIVITY_DELIVERABLES_URL: '/activity_deliverables',
  ACTIVITY_DASHBOARD_URL: '/activity_dashboard',
}