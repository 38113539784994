import React from 'react'
import SortableTable from "../../tables/SortableTable"
import { appConstants } from '../../../utils/constants.js'
import { BsPen } from "react-icons/bs"
import I18n from '../../../i18n-js/locales.js'
import { SelectColumnFilter } from '../../tables/tableFilters.js'

export default function ActivityNotes(props) {

  const tableColumns = [
    {
      "Header": I18n.t('activerecord.models.activity'),
      "accessor": "activity",
      Filter: SelectColumnFilter,
      filter: 'equals',
      show: props.showActivity ? true : false,
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_note.name'),
      "accessor": "name"
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_note.activity_note_type'),
      "accessor": "activity_note_type",
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_note.updated_at'),
      "accessor": "updated_at",
      disableFilters: true,
    },
    {
      "Header": I18n.t('labels.updated_by'),
      "accessor": "updated_by",
      Filter: SelectColumnFilter,
      filter: 'equals'
    },
    {
      "Header": I18n.t('activerecord.attributes.activity_note.description'),
      "accessor": "description",
      disableFilters: true,
      show: props.showDescription ? true : false,
    }
  ]

  // Edit link for activity list
  const editLink = (cell, row) => {
    return (
      cell["column"]["id"] === "name"
        ? <p className="trim-name">{row["original"]["name"]} {row["original"]["can_edit"] && props.canEdit ? <a href={`${appConstants.ACTIVITY_NOTES_URL}/${row["original"]["id"]}/edit`} data-turbo-frame='modal'> <BsPen /></a> : "" }</p>
        : cell["column"]["id"] === "activity"
        ? <p><a href={`${appConstants.ACTIVITY_DASHBOARD_URL}?activity_id=${row["original"]["activity_id"]}`}>{row["original"]["activity"]}</a></p>
          : cell.render('Cell')
    )
  }

  return (
    <SortableTable
      data={props.activityNotes}
      columns={tableColumns}
      useRenderFunction={true}
      cellContent={editLink}
    />  
  )
}

